import React from "react";

class ScrollButton extends React.Component {
  handleScroll = () => {
    // Find the target element with the class name 'row_photos'
    const targetElement = document.querySelector(".row_photos");

    if (targetElement) {
      // Calculate the scroll distance, considering the padding (100vh + 50px)
      const paddingTop = 50; // Add your desired padding top in pixels
      const scrollDistance =
        targetElement.getBoundingClientRect().top - paddingTop;

      // Scroll to the target element using smooth behavior
      window.scrollBy({ top: scrollDistance, behavior: "smooth" });
    }
  };

  render() {
    return (
      <button
        type="submit"
        className="btn btn-primary"
        onClick={this.handleScroll}
      >
        <h3>Some Photos</h3>
      </button>
    );
  }
}

export default ScrollButton;
