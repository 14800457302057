import React from "react";
import "./projects.css";
import { FaExternalLinkAlt } from "react-icons/fa";

const Projects = () => {
  return (
    <section id="projects">
      <div className="projects">
        <div class="projects_all">
          <div class="project_heading">
            <h1>My Projects:</h1>
          </div>
          <div class="project_content">
            <div class="card">
              <div class="card-header">
                Sobel Filter Edge Detection — Python: Flask, HTML, CSS (04/2022){" "}
                <a
                  href="https://github.com/surajgiri7/Edge_Detection_Using_Sobel_Filter"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>
                A complete application to upload an image, detect the edges of
                that image using Sobel filter, and produce a resulting image
                with the detected edges which could then be downloaded.
              </p>
            </div>

            <div class="card">
              <div class="card-header">
                Stochastic Methods Lab (09/2022 - 12/2022){" "}
                <a
                  href="https://github.com/surajgiri7/Edge_Detection_Using_Sobel_Filter"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>
                A complete application to upload an image, detect the edges of
                that image using Sobel filter, and produce a resulting image
                with the detected edges which could then be downloaded.
              </p>
            </div>

            <div class="card">
              <div class="card-header">
                Corona Archive — Python: Flask, HTML, CSS, Javascript, PHP
                (03/2022 - 04/2022){" "}
                <a
                  href="https://github.com/surajgiri7/Jacobs_University_Bremen_Software_Engineering"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>
                A complete platform for tracing of the Covid infected patients,
                recent public places where those COVID infected patients might
                have visited.
              </p>
            </div>

            <div class="card">
              <div class="card-header">
                Weather Forecasting App — React.JS (01/2022){" "}
                <a
                  href="https://github.com/surajgiri7/Weather_App_Using_React.JS"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>
                A front end application for forecasting the weather of any
                inputted City using the weather API from openweathermap.org.
              </p>
            </div>

            <div class="card">
              <div class="card-header">
                Color-Detection — Python: OpenCV and Pandas (02/2022){" "}
                <a
                  href="https://github.com/surajgiri7/Color_Detection-Pandas_and_OpenCV"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>Color Detection using OpenCV and Pandas</p>
            </div>

            <div class="card">
              <div class="card-header">
                Straight Outta Kitchen — HTML, CSS, PHP, Python (09/2021 -
                11/2021){" "}
                <a
                  href="https://github.com/surajgiri7/Straight_Outta_Kitchen"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>
                A full stack E-Commerce website for a restaurant developed using
                HTML and CSS for front-end and PHP for back-end with MySQL DBMS
                and access log script in python.
              </p>
            </div>

            <div class="card">
              <div class="card-header">
                To-Do App — Python (07/2021){" "}
                <a
                  href="https://github.com/surajgiri7/Simple_Todo_App_Using_React.JS/tree/main/todo-app"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>To-Do App using tkinter package in Python.</p>
            </div>

            <div class="card">
              <div class="card-header">
                To-Do App — React.JS.(07/2021){" "}
                <a
                  href="https://github.com/surajgiri7/Simple_Todo_App_Using_React.JS"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                  <FaExternalLinkAlt size={20} />
                </a>
              </div>
              <p>To-Do App using React.JS with a final touch of CSS.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
