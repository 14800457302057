import React, { useRef } from "react";
import "./contact.css";
import { MdEmail, MdPhone } from "react-icons/md";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_12h4wey",
        "template_n0qtlee",
        form.current,
        "EeIxuwocp8Iia1xyj"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section id="contact">
      <div className="contact">
        <div className="contact_all">
        <div className="contact_heading">
          <h1>Contact Me</h1>
        </div>
        <div className="container contact_container">
          <div className="contact_options">
            <article className="contact_option">
              <MdEmail className="contact_option-icon" />
              <h4>Email</h4>
              {/* <h5>surajgiri4210@gmail.com</h5> */}
              <a href="mailto:surajgiri4210@gmail.com" target="_blank" rel="noopener noreferrer">
                surajgiri4210@gmail.com
              </a>
            </article>
            <article className="contact_option">
              <MdPhone className="contact_option-icon" />
              <h4>Phone Number</h4>
              {/* <h5>+49 15737818961</h5> */}
              <a href="tel:+4915737818961" target="_blank" rel="noopener noreferrer">
                +4915737818961
              </a>
            </article>
          </div>

          {/* |||||||||||||||||||||||||||||||||||||||||||  */}

          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="name"
              placeholder="Your Full Name"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your email"
              required
            />
            <textarea
              name="message"
              rows="10"
              placeholder="Your message"
              required
            ></textarea>
            <button type="submit" className="btn btn-primary">
              Send Message
            </button>
          </form>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
